import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { Container } from "react-grid-system"

import "./404.scss";

const NotFoundPage = () => {
  return (
    <Layout pageClass="error-page">
      <main className="error-wrap">
        <Container>
          <h1>404</h1>
          <p>The page you are looking for cannot be found.</p>
          <Link to="/" className="btn">Back to home</Link>
        </Container>
      </main>
    </Layout>
  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
